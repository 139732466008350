import { ColumnWidthOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Popconfirm, Row, Select, Spin, Tooltip, message, notification, Checkbox, InputNumber, Slider, Col, Radio } from 'antd';
import { Color, PostProcessStageLibrary } from 'cesium';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fi";
import "moment/locale/sv";
import "moment/locale/vi";
import { Fragment, useCallback, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams, withRouter } from 'react-router-dom';
import HelpButton from '../../elements/HelpButton';
import { CustomModal, FormStyled, Statistics } from './style';
import './style.less';
import Axios from 'axios';
import QueryBuilder from './QueryBuilder';
import { toJS } from 'mobx';
const Cesium = require('cesium')
const QueryDrawer = ({ objectQueryStore, commonStore, projectStore, viewer, uiStore }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
  const [drawerWidth, setDrawerWidth] = useState(0);
  const [totalResult, setTotalResult] = useState(0)
  const [listAlignmentGuid, setListAlignmentGuid] = useState([])
  const [objectsInput, setObjectsInput] = useState(0)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [formQuery] = Form.useForm();
  const [treeJS, setTreeJs] = useState({})
  const [treeJsStringOld, setTreeJsStringOld] = useState('')
  moment.locale(`${commonStore.language}`)
  const [isShowModalQuery, setOpenModalAddQuery] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(false);
  const [treeInit, setTreeInit] = useState({});
  const [srcQuery, setSrcQuery] = useState(false);
  const [stylingTypeActive, setStylingTypeActive] = useState('result');
  const [_elapsedTime, setElapsedTime] = useState(0);
  const { projectId } = useParams()
  const [options, setOptions] = useState([]);
  const [isSaveNewQuery, setIsSaveNewQuery] = useState(false);
  var silhouetteGreen = PostProcessStageLibrary.createEdgeDetectionStage()
  silhouetteGreen.uniforms.color = Color.LIME
  silhouetteGreen.uniforms.length = 0.01
  moment.locale(`${commonStore.language}`)
  const unselectFeatures = () => {
    projectStore.setListObjectColor({})
  }

  const getColorAlignment = (feature, type, originalColor) => {
    let color = null;
    if (type) {
      let colorQuery = objectQueryStore.styleQuery?.data?.[type]?.find(x => x.id === 'color')
      let alpha = objectQueryStore.styleQuery?.data?.[type]?.find(x => x.id === 'alpha')
      let show = objectQueryStore.styleQuery?.data?.[type]?.find(x => x.id === 'show')
      let finalAlpha = 1;
      if (show.checked) {
        if (show.value && alpha.checked) {
          finalAlpha = 1 - alpha.value;
        } else if (show.value && !alpha.checked) {
          finalAlpha = 1;
        } else if (!show.value) {
          finalAlpha = 0;
        }
      } else {
        if (alpha.checked) {
          finalAlpha = 1 - alpha.value;
        } else {
          finalAlpha = 1;
        }
      }
      color = Color.fromCssColorString(colorQuery.checked ? colorQuery.value : '#FFFFFF')
        .withAlpha(finalAlpha)
    } else {
      color = Color.fromCssColorString(originalColor)
    }
    const AlignmentHorCurve = new Cesium.PolylineDashMaterialProperty({
      color: Color.clone(color, feature.color),
    })
    return AlignmentHorCurve;
  }

  const highlighXMLAlignment = (features, type) => {
    features.map(feature => {
      feature.polyline.material = getColorAlignment(feature, type);
    })
    if (viewer.scene.requestRenderMode) {
      viewer.scene.requestRender()
    }
  }

  const unHighlighXMLAlignment = features => {
    if (!features || !features.length) {
      return;
    }
    features.map(feature => {
      // setCZMLStyle(feature)
      feature.polyline.material = getColorAlignment(feature, false, feature?.originalColor || Color.WHITE,);
    })
    setListAlignmentGuid([])
  }

  //#region event for mouse and touche split panel
  const handleTouchStart = e => {
    e.preventDefault();
    if (e.touches.length !== 1) return null;
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd, { passive: false });
    document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
  };

  const handleTouchMove = useCallback(e => {
    let touch = e.touches[0] || e.changedTouches[0];
    let target = document.elementFromPoint(touch.clientX, touch.clientY);
    let newWidth = (document.body.offsetWidth ? document.body.offsetWidth : 0) - (touch.clientX - (target && target.offsetLeft ? target.offsetLeft : 0));
    let minDrawerWidth = 300;
    if (newWidth < 0) {
      setDrawerWidth(10);
    }
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth);
    }
    if (newWidth > window.innerWidth) {
      setDrawerWidth(window.innerWidth);
    }
  }, []);

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, { passive: false });
    document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
    document.removeEventListener("touchmove", handleTouchMove, { passive: false });
  };

  const handleMouseDown = e => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(e => {
    let newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    let minDrawerWidth = 300;
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth);
    }
    if (newWidth > window.innerWidth) {
      setDrawerWidth(window.innerWidth);
    }
  }, []);
  //#endregion

  const handleChangeScreenSize = () => {
    setScreenWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleChangeScreenSize)

  useEffect(() => {
    if (drawerWidth > screenWidth) {
      setDrawerWidth(screenWidth)
    }
  }, [screenWidth])

  useEffect(() => {
    const queries = !objectQueryStore?.listObjectsQuery
      ? []
      : objectQueryStore.listObjectsQuery.map(c => ({
        label: c.name,
        value: c.id,
      }));

    setOptions(queries);
  }, [objectQueryStore?.listObjectsQuery]);

  useEffect(() => {
    form.resetFields();
    if (objectQueryStore.isShowListQuery) {
      objectQueryStore.getByProjectId(projectId)
      setDrawerWidth((document.body.offsetWidth * 25) / 100)
    }
    return () => {
      setObjectsInput(0)
      setListAlignmentGuid([])
      setElapsedTime(0)
    }
  }, [objectQueryStore.isShowListQuery])

  const onChangeStyleQuery = (type, key, value, checked) => {
    let styleQuery = JSON.parse(JSON.stringify(objectQueryStore.styleQuery.data))
    if (!styleQuery[type] && type === 'result') {
      styleQuery[type] = [
        {
          id: 'color',
          value: '#FF0000',
          checked: true,
        },
        {
          id: 'alpha',
          value: 0,
          checked: false,
        },
        {
          id: 'show',
          value: true,
          checked: false,
        }
      ]
    } else if (!styleQuery[type] && type === 'scene') {
      styleQuery[type] = [
        {
          id: 'color',
          value: '#808080',
          checked: true,
        },
        {
          id: 'alpha',
          value: 0,
          checked: false,
        },
        {
          id: 'show',
          value: true,
          checked: false,
        }
      ]
    }
    for (let i = 0; i < styleQuery[type]?.length; i++) {
      if (styleQuery[type][i].id === key) {
        if (value !== 'no') {
          styleQuery[type][i].value = value;
        }
        if (checked !== 'no') {
          styleQuery[type][i].checked = checked;
        }
        break;
      }
    }
    objectQueryStore.setStyleQuery({ ...objectQueryStore.styleQuery, data: styleQuery })
  }
  const handleSubmit = () => {
    formQuery.validateFields().then(res => {
      const { name } = res;
      form.validateFields().then(async values => {
        let payload = {
          name,
          queryParameters: {
            queryExpression: JSON.stringify(treeJS),
            styleQuery: objectQueryStore.styleQuery?.data
          },
          project: projectId,
          type: 'multiple',
          src: ''
        }
        if (selectedQuery && !isSaveNewQuery) {
          objectQueryStore.setIsLoading(true)
          if (srcQuery) {
            payload.src = srcQuery
          }
          await objectQueryStore.update(selectedQuery?.id, payload).then(c => {
            message.success(`${t('commons.update')} ${selectedQuery?.name} ${t('successfully')}`);
            objectQueryStore.setIsLoading(false)
            setOpenModalAddQuery(false)
            setSelectedQuery(c)
            const treeinit = getTreeInit(c.queryParameters?.queryExpression);
            setTreeInit(treeinit)
            setTreeJsStringOld(JSON.stringify(treeinit)?.replace(/\s+/g, ' ')?.trim());
            if (c.queryParameters.styleQuery) {
              objectQueryStore.setStyleQuery({ ...objectQueryStore.styleQuery, data: c.queryParameters.styleQuery })
            }
            let index = objectQueryStore?.listObjectsQuery.findIndex(k => k.id === c.id)
            if (index !== -1 && objectQueryStore.listObjectsQuery) {
              objectQueryStore.listObjectsQuery[index] = c
              const newOptions = [...options];
              newOptions[index] = {
                ...newOptions[index],
                label: name,
              };
              setOptions(newOptions);
            }
            formQuery.resetFields()
          }).catch(err => {
            objectQueryStore.setIsLoading(false)
          })
        } else {
          setLoading(true)
          await objectQueryStore.create(payload).then(c => {
            setSelectedQuery(c)
            const treeinit = getTreeInit(c.queryParameters?.queryExpression);
            setTreeInit(treeinit)
            setTreeJsStringOld(JSON.stringify(treeinit)?.replace(/\s+/g, ' ')?.trim());
            if (c.queryParameters.styleQuery) {
              objectQueryStore.setStyleQuery({ ...objectQueryStore.styleQuery, data: c.queryParameters.styleQuery })
            }
            message.success(t("create-new-query-successfully"))
            setLoading(false)
            setOpenModalAddQuery(false)
          }).catch(err => {
            setLoading(false)
          })
          formQuery.resetFields()
        }
      })
    })
  }

  const handleAddNewQueries = () => {
    form.validateFields().then(res => {
      setOpenModalAddQuery(true)
      setIsSaveNewQuery(false)
      if (selectedQuery) {
        formQuery.setFieldsValue({ name: selectedQuery?.name })
      }
    })
  }

  const handleSaveNewQueries = () => {
    form.validateFields().then(() => {
      setOpenModalAddQuery(true)
      setIsSaveNewQuery(true)
    })
  }

  const onClear = () => {
    if (viewer.scene.requestRenderMode) { viewer.scene.requestRender(); }
    objectQueryStore.setStyleQuery({
      ...objectQueryStore.styleQuery, data: {
        result: [
          {
            id: 'color',
            value: '#FF0000',
            checked: true,
          },
          {
            id: 'alpha',
            value: 0,
            checked: false,
          },
          {
            id: 'show',
            value: true,
            checked: false
          }
        ],
        scene: [
          {
            id: 'color',
            value: '#808080',
            checked: true,
          },
          {
            id: 'alpha',
            value: 0,
            checked: false,
          },
          {
            id: 'show',
            value: true,
            checked: false
          }
        ],
      }
    })
    unselectFeatures()
    unHighlighXMLAlignment(listAlignmentGuid)
    form.resetFields()
    setSelectedQuery(false)
    setTreeInit({})
    setTreeJsStringOld('');
    setObjectsInput(0)
    setElapsedTime(0)
    setTotalResult(0)
    setLoading(false)
  }

  const onNewQuery = () => {
    onClear()
  }

  const deleteQuery = async payload => {
    if (payload) {
      objectQueryStore.setIsLoading(true)
      await objectQueryStore
        .delete(payload?.id)
        .then(c => {
          message.success(t('delete-object-query-successfully'))
          setSelectedQuery(false)
          setTreeInit({})
          setTreeJsStringOld('');
          objectQueryStore.setIsLoading(false)
          setOpenModalAddQuery(false)
          onClear()
        })
        .catch(err => {
          objectQueryStore.setIsLoading(false)
        })
    }
  }


  const onChangeSelect = value => {
    const isExist = objectQueryStore.listObjectsQuery.find(
      c => c.id === value
    )
    setSelectedQuery(isExist)
    if (isExist.queryParameters.styleQuery) {
      objectQueryStore.setStyleQuery({ ...objectQueryStore.styleQuery, data: isExist.queryParameters.styleQuery })
    }
    const queryParameters = isExist?.queryParameters || {}
    setSrcQuery(isExist.src)
    let queryExpression = queryParameters?.queryExpression?.replace(/\\"/g, '"')
    const isCheck = parseConditionString(queryExpression);
    if (isExist.type !== 'multiple') {
      queryExpression = isCheck ? `{
        "title": "AND",
        "type": "operator",
        "children": [
          {
            "title": "${queryExpression}",
            "type": "attribute",
            "arrayModel":[]
          }
        ]
      }` : {}
    }
    const treeinit = getTreeInit(queryExpression);
    setTreeInit(treeinit)
    setTreeJsStringOld(JSON.stringify(treeinit)?.replace(/\s+/g, ' ')?.trim());
    form.setFieldsValue({
      type: 'multiple',
      stylingExpression: queryParameters?.stylingExpression
    })
  }

  function parseConditionString(conditionString) {
    const regex = /^(Attribute|Object)\.(.*?)\s*(!=|>=|<=|==|=|>|<)\s*(.*)$/;
    const match = conditionString.match(regex);
    if (match) {
      const attribute = match[2].trim();
      const condition = match[3] === '=' ? '==' : match[3];
      const value = match[4].trim();
      return { attribute, condition, value };
    }

    return null;
  }
  const getListGuidHighlight = async () => {
    objectQueryStore.setStyleQuery({ ...objectQueryStore.styleQuery, status: true });
    if (viewer.scene?.primitives?._primitives?.length > 0) {
      form.validateFields().then(async values => {
        objectQueryStore.setIsLoading(true)
        const arrayModelProject = projectStore.modelList.filter(x => x.type === 'ifc' || x.type === 'landxml' || x.type === 'landxmlBackground' || x.type === 'cad').map(x => {
          return {
            _id: x._id,
            hash: x.hash,
            isObjectInforsReady: x.isObjectInforsReady,
            name: x.name
          }
        });
        let checkResult = await objectQueryStore.checkObjectInforsReady(projectId, {
          arrayModelProject
        }).catch(err => {
          setElapsedTime(0)
          return err;
        });
        if (checkResult?.status === undefined) {
          if (!checkResult || checkResult?.filter(x => x?.isObjectInforsReady === false).length > 0) {
            objectQueryStore.setIsLoading(false)
            notification.open({
              message: t('database-indexing-is-still-running-for-new-objects'),
              icon: <InfoCircleOutlined style={{ color: '#faad14' }} />
            })
            return;
          }
        }

        let res = {}
        const startTime = Date.now();
        setTotalResult(0)
        if (Object.keys(treeJS).length > 0) {
          const params = {
            queryExpression: treeJS,
          }
          let isNewQuery = false;
          const isCheckJson = treeJsStringOld === JSON.stringify(treeJS)?.replace(/\s+/g, ' ')?.trim()
          if (!isCheckJson) {
            isNewQuery = true
          } else if (!srcQuery || selectedQuery?.isReset) {
            isNewQuery = true
          }
          if (isNewQuery) {
            res = await objectQueryStore
              .getListGuidHighlight(projectId, params).catch(err => {
                setElapsedTime(0)
                message.error(t('querry-error'))
              })
            if (selectedQuery?.id && isCheckJson) {
              const dataQueryNew = await objectQueryStore.update(selectedQuery?.id, { isReset: false, src: res?.src, type: 'multiple', 'queryParameters.queryExpression': JSON.stringify(treeJS) })
              setSelectedQuery(dataQueryNew)
              const treeinit = getTreeInit(dataQueryNew?.queryParameters?.queryExpression);
              setTreeInit(treeinit)
              setTreeJsStringOld(JSON.stringify(treeinit)?.replace(/\s+/g, ' ')?.trim());
            } else {
              setTreeJsStringOld(JSON.stringify(treeJS)?.replace(/\s+/g, ' ')?.trim());
            }
            setSrcQuery(res?.src)
          } else {
            const response = await Axios.get(srcQuery)
            res = response.data
            const total = await objectQueryStore
              .getTotalObjectModelForProject(projectId)
            res.totalObject = total
          }
          const endTime = Date.now();
          const elapsedTime = endTime - startTime;
          if (res?.listGuid) {
            setElapsedTime(elapsedTime)
            if (viewer.scene.requestRenderMode) {
              viewer.scene.requestRender()
            }
            unselectFeatures()
            if (listAlignmentGuid.length > 0) {
              if (viewer.scene.requestRenderMode) {
                viewer.scene.requestRender()
              }
              unHighlighXMLAlignment(listAlignmentGuid)
            }

            let listNewFeature = {}
            let listAlignmenteature = []
            let listAlignmenteatureResults = [];
            res.listGuid.map(item => {
              if (item.guid && item.modelId) {
                if (!listNewFeature[item.modelId]) {
                  listNewFeature[item.modelId] = {}
                }
                listNewFeature[item.modelId][item.guid] = 'hightlight'
                const _alignment = projectStore.alignmentFeatures.find(c => c.modelId === item.modelId && c._id === item?.guid)
                if (_alignment) {
                  _alignment.guid = item.guid
                  const originalColor = _alignment.polyline.material.getValue().color.toCssHexString()
                  if (!_alignment.originalColor) {
                    _alignment.originalColor = originalColor
                  }
                  listAlignmenteatureResults.push(_alignment)
                  listAlignmenteature.push(_alignment)
                }
              }
            });
            let listAlignmenteatureScene = []
            projectStore.alignmentFeatures.map(x => {
              if (!listAlignmenteatureResults.find(y => x.id === y.id)) {
                const originalColor = x.polyline.material.getValue().color.toCssHexString()
                if (!x.originalColor) {
                  x.originalColor = originalColor
                }
                listAlignmenteatureScene.push(x)
                listAlignmenteature.push(x)
              }
            })
            projectStore.setListObjectColor(listNewFeature)
            highlighXMLAlignment(listAlignmenteatureResults, 'result')
            highlighXMLAlignment(listAlignmenteatureScene, 'scene')
            setTotalResult(res.totalResult || 0)
            setObjectsInput(res.totalObject || 0)
            setListAlignmentGuid(listAlignmenteature)
          }

        }
        objectQueryStore.setIsLoading(false)
      })
    }
  }

  const confirmDelete = () => {
    deleteQuery(selectedQuery)
  }

  const cancelDelete = () => {
  }

  const getTreeInit = (value) => {
    if (!value) return {}
    try {
      if (typeof value === 'string') {
        value = value?.replace(/\\"/g, '"')
        return JSON.parse(value);
      } else {
        return JSON.parse(JSON.stringify(value))
      }
    } catch (error) {
      return {};
    }
  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="help-btn-wrapper">
            {t('query')}
            <Tooltip
              title={t('commons.help')}
              placement="bottom"
              overlayStyle={
                isMobile || isTablet ? { display: 'none' } : undefined
              }>
              <div>
                <HelpButton helppage={'query_object'} hovered={'black'} />
              </div>
            </Tooltip>
          </div>
        }
        placement="right"
        width={drawerWidth}
        zIndex={isShowModalQuery ? 9998 : 9999}
        closable={true}
        onClose={e => {
          objectQueryStore.setIsShowListQuery(false)
          objectQueryStore.setStyleQuery({ ...objectQueryStore.styleQuery, status: false });
          onClear()
        }}
        visible={objectQueryStore.isShowListQuery}
        maskClosable={false}
        mask={false}
        destroyOnClose={true}
        forceRender
        className={
          objectQueryStore.isShowListQuery
            ? 'QueryDrawer custom-wraper-splitPanel'
            : ''
        }>
        {isTabletOrMobileDevice ? (
          <div className="splitpanel-mobile">
            <Button
              onTouchStart={e => handleTouchStart(e)}
              type="dashed"
              shape="circle"
              className="btnSplitPanel">
              <ColumnWidthOutlined />
            </Button>
          </div>
        ) : (
          <div onMouseDown={e => handleMouseDown(e)} className="splitpanel" />
        )}
        <Spin spinning={objectQueryStore.isLoading}>
          <div
            style={{
              width: '100%',
              background: '#fff',
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Row gutter={[8, 8]}>
              <Button
                className="button-tool"
                type="primary"
                onClick={onNewQuery}>
                {t('commons.new')}
              </Button>
              <Button
                className="button-tool"
                type="primary"
                onClick={handleAddNewQueries}>
                {t('commons.save')}
              </Button>
              {selectedQuery && <Button
                className="button-tool"
                type="primary"
                onClick={handleSaveNewQueries}>
                {t('save-new')}
              </Button>
              }
              {selectedQuery && (
                <Popconfirm
                  title={t('are-you-sure-to-delete-this-query')}
                  onConfirm={confirmDelete}
                  onCancel={cancelDelete}
                  okText={t('commons.yes')}
                  cancelText={t('commons.no')}
                >
                  <Button
                    className="button-tool"
                    type="primary">
                    {t('commons.delete')}
                  </Button>
                </Popconfirm>
              )}
            </Row>
            <Row>
              <Button
                type="primary"
                className="lastbtn"
                onClick={getListGuidHighlight}>
                {t('run')}
              </Button>
            </Row>
          </div>
          <FormStyled>
            <Form form={form} layout="vertical">
              <Form.Item
                rules={[{ required: false }]}
                label={t('load')}>
                <Input.Group compact className="input-group">
                  <Select
                    style={{ width: 'calc(100%)' }}
                    showSearch
                    placeholder={t("search-to-select-your-query")}
                    onChange={onChangeSelect}
                    value={selectedQuery?.id}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={options}
                  />
                </Input.Group>
              </Form.Item>
              <QueryBuilder className="query-builder"
                selectedQuery={selectedQuery}
                setTreeJs={setTreeJs}
                treeJS={treeJS}
                treeInit={treeInit}
                setSrcQuery={setSrcQuery}
                projectId={projectId}
              ></QueryBuilder>
              <Statistics style={{ marginTop: 15 }}>
                <span className='label flex-center-global' style={{ fontSize: 20 }}>{t('result-styling')}</span>
              </Statistics>
              <div>
                <div className='flex-center-global'>
                  <Radio.Group name="radiogroup" defaultValue={stylingTypeActive}>
                    <Radio onChange={() => setStylingTypeActive('result')} value={'result'} style={{ marginRight: 10 }}>{t('result')}</Radio>
                    <Radio onChange={() => setStylingTypeActive('scene')} value={'scene'}>{t('scene')}</Radio>
                  </Radio.Group>
                </div>
                <div className='flex-center'>
                  <Checkbox
                    onChange={(e) => onChangeStyleQuery(stylingTypeActive, 'color', 'no', e.target.checked)}
                    checked={objectQueryStore.styleQuery?.data?.[stylingTypeActive]?.find(x => x.id === 'color')?.checked}
                  ></Checkbox>
                  <span className='label'>&nbsp;{t('color')}:&nbsp;</span>
                  <input type='color' onChange={(e) => onChangeStyleQuery(stylingTypeActive, 'color', e.target.value, 'no')}
                    value={objectQueryStore.styleQuery?.data?.[stylingTypeActive]?.find(x => x.id === 'color')?.value}></input>
                </div>
                <div className='flex-center'>
                  <Checkbox
                    onChange={(e) => onChangeStyleQuery(stylingTypeActive, 'alpha', 'no', e.target.checked)}
                    checked={objectQueryStore.styleQuery?.data?.[stylingTypeActive]?.find(x => x.id === 'alpha')?.checked}
                  ></Checkbox>
                  <span className='label'>&nbsp;{t('transparency')}:&nbsp;</span>
                  <Row className='flex-center'>
                    <Col span={12}>
                      <Slider
                        min={0}
                        max={1}
                        step={0.1}
                        onChange={(e) => onChangeStyleQuery(stylingTypeActive, 'alpha', e, 'no')}
                        value={objectQueryStore.styleQuery?.data?.[stylingTypeActive]?.find(x => x.id === 'alpha')?.value}
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={0}
                        max={1}
                        step={0.1}
                        onChange={(e) => onChangeStyleQuery(stylingTypeActive, 'alpha', e, 'no')}
                        value={objectQueryStore.styleQuery?.data?.[stylingTypeActive]?.find(x => x.id === 'alpha')?.value}
                        style={{
                          margin: '0 16px',
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <div className='flex-center'>
                  <Checkbox
                    onChange={(e) => onChangeStyleQuery(stylingTypeActive, 'show', 'no', e.target.checked)}
                    checked={objectQueryStore.styleQuery?.data?.[stylingTypeActive]?.find(x => x.id === 'show')?.checked}
                  ></Checkbox>
                  <span className='label'>&nbsp;{t('visible')}:&nbsp;</span>
                  <Checkbox
                    onChange={(e) => onChangeStyleQuery(stylingTypeActive, 'show', e.target.checked, 'no')}
                    checked={objectQueryStore.styleQuery?.data?.[stylingTypeActive]?.find(x => x.id === 'show')?.value}
                  ></Checkbox>
                </div>
              </div>
              <Statistics style={{ marginTop: 15 }}>
                <span className='label'>{t('statistics')}</span>
                <div className='category'>
                  <span className='label-category'>{t('total-objects')}:</span>
                  <span>{objectsInput || 0}</span>
                </div>
                <div className='category'>
                  <span className='label-category'>{t('result-objects')}:</span>
                  <span>{totalResult || 0}</span>
                </div>
                <div className='category'>
                  <span className='label-category'>{t('query-time')}:</span>
                  <span>{`${_elapsedTime ? _elapsedTime : 0}ms`}</span>
                </div>
              </Statistics>
            </Form>
            <CustomModal
              title={selectedQuery && !isSaveNewQuery ? t("update-query") : t("new-query")}
              centered
              maskClosable={false}
              top={150}
              visible={isShowModalQuery}
              onOk={() => setOpenModalAddQuery(false)}
              onCancel={() => setOpenModalAddQuery(false)}
              zIndex={9999}
              className="add-query-modal"
              footer={[
                <div key="footer-action" className="action-wrap">
                  <Button
                    disabled={isLoading}
                    key="cancel"
                    onClick={() => setOpenModalAddQuery(false)}>
                    {t('commons.cancel')}
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    form={'invite-license-form'}
                    key="submit"
                    loading={isLoading}
                    type="primary">
                    {t('commons.ok')}
                  </Button>
                  ,
                </div>,
              ]}>
              <Form form={formQuery} layout="vertical">
                <Form.Item
                  rules={[
                    { required: true },
                    { message: t('please-input-your-query-name') },
                  ]}
                  label={t('name')}
                  name="name">
                  <Input
                    placeholder={t("input-your-query-name")}
                    style={{ width: '100%' }}></Input>
                </Form.Item>
              </Form>
            </CustomModal>
          </FormStyled>
        </Spin>
      </Drawer>
    </Fragment>
  )
}

export default withRouter(inject(
  'objectQueryStore',
  'commonStore',
  'projectStore',
  'uiStore'
)(observer(QueryDrawer)))
