
import { Color, PinBuilder, PointGraphics, HeightReference } from 'cesium'
import { GeoJsonDataSource } from 'resium'
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Utils from '../../../../utils'
import uuid from 'uuid'
import axios from 'axios'
import GML3 from 'ol/format/GML3.js';
import GML2 from 'ol/format/GML2.js';
import GML32 from 'ol/format/GML32.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4.js';
var Cesium = require('cesium');

const GeoJsonView = ({ projectStore, uiStore, viewer, modelListWFS, geoJsonViewLink, modelStore, canPicking, userGroupStore }) => {
    const [geoJsonViews, setGeoJsonViews] = useState([]);
    useEffect(() => {
        if (!geoJsonViewLink) {
            if (projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0) {
                const visibleTileViewIds = projectStore.visibleTilesets.map(item => {
                    if (item.isVisible) {
                        return item.modelId + '-tile'
                    }
                })
                geoJsonViews.forEach(geoJsonView => {
                    let geoJsonElement = geoJsonView.ref.current.cesiumElement
                    const splitKey = geoJsonView.key.split('-');
                    if (!(visibleTileViewIds.indexOf(splitKey[0] + '-' + splitKey[1]) > -1)) {
                        geoJsonElement.show = false;
                    } else {
                        geoJsonElement.show = true;
                    }
                })
            }
        }
        if (viewer.current.cesiumElement?.scene?.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }, [projectStore.visibleTilesets])

    useEffect(() => {
        if (geoJsonViewLink) {
            if (geoJsonViews && geoJsonViews.length > 0) {
                let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds(true);
                geoJsonViews.map(geoJsonView => {
                    const geoJsonElement = geoJsonView?.ref?.current?.cesiumElement
                    if (geoJsonElement) {
                        let isShow = true;
                        const splitKey = geoJsonView.key.split('-');
                        let idElement = splitKey[0] || geoJsonView?.props?.id
                        const model = projectStore.listAllModel3DSLink.find(c => c.id === idElement);
                        if (model && model?.isVisible4D) {
                            if (visibleTileViewIds.includes(geoJsonView.key)) {
                                geoJsonElement.show = true;
                            }
                            if (projectStore.hiddenModelLinkIDs.includes(idElement)) {
                                isShow = false
                            }
                            geoJsonElement.show = isShow;
                        }
                    }
                })
            }
        } if (viewer.current.cesiumElement?.scene?.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }, [projectStore.listProjectLink, geoJsonViews.length, projectStore.hiddenModelLinkIDs])

    const onClickFeature = (tile, model) => {
        if (tile && tile.id) {
            const attrData = {
                pTitle: model.data.layers,
                pKey: model.id,
                geoJsonDataSource: true,
                refId: model.id,
                children: [],
                type: "model"
            };
            const id = tile.id._id ? tile.id._id : '';
            attrData.children.push({
                key: uuid(),
                title: `Feature Id = ${id}`
            })

            const propertyNames = tile.id.properties && tile.id.properties.propertyNames ? tile.id.properties.propertyNames : []
            for (let i = 0; i < propertyNames.length; ++i) {
                const name = propertyNames[i];
                const value = tile.id.properties[name]._value;
                if (value) {
                    attrData.children.push({
                        key: uuid(),
                        title: `${name} = ${value}`
                    })
                }
            }

            uiStore.setShowAttrPanel(true);
            projectStore.setSelectedAttrData(attrData);
            if (!geoJsonViewLink)
                modelStore.setModelAttribute({ id: model.id, version: 0, GUID: id })
        }
    }

    const gml2geojson = (gml, version) => {
        let features
        if (version === '1.0.0') {
            features = new GML2().readFeatures(gml);
        } else if (version === '2.0.0') {
            features = new GML32().readFeatures(gml);
        } else {
            features = new GML3().readFeatures(gml);
        }
        let geoJsonStr = new GeoJSON().writeFeatures(features);
        let shape = JSON.parse(geoJsonStr)
        if (version === '1.0.0') {
            let _multiGeometry = []
            for (const [index, v] of shape.features.entries()) {
                if (v.geometry) {
                    let featureType = v.geometry.type // Point; LineString; Polygon; MultiPoint; MultiLineString; MultiPolygon
                    let coordinates = v.geometry.coordinates;
                    let stringCoordinates = JSON.stringify(coordinates);
                    let sliceCoordinates1
                    let sliceCoordinates2
                    let array;
                    let newArr = [];
                    if (featureType === "Polygon") {
                        coordinates.forEach(element => {
                            let _array = [];
                            let stringCoordinates = JSON.stringify(element);
                            sliceCoordinates1 = stringCoordinates.slice(1);
                            sliceCoordinates2 = sliceCoordinates1.slice(0, -1);
                            array = JSON.parse("[" + sliceCoordinates2 + "]");
                            for (let i = 0; i < array.length; i++) {
                                _array.push([array[i][1], array[i][0], array[i][2]]);
                            }
                            newArr.push(_array)
                        });
                    } else if (featureType === 'LineString') {
                        sliceCoordinates1 = stringCoordinates.slice(1);
                        sliceCoordinates2 = sliceCoordinates1.slice(0, -1);
                        array = JSON.parse("[" + sliceCoordinates2 + "]");
                        for (let i = 0; i < array.length; i++) {
                            newArr.push([array[i][1], array[i][0], array[i][2]]);
                        }
                    } else if (featureType === 'Point') {
                        array = JSON.parse("[" + stringCoordinates + "]");
                        for (let i = 0; i < array.length; i++) {
                            newArr.push(array[i][1], array[i][0], array[i][2]);
                        }
                    } else if (featureType === 'MultiPolygon') {
                        console.log("MultiPolygon")
                    } else if (featureType === 'MultiLineString') {
                        console.log("MultiLineString")
                    } else if (featureType === 'MultiPoint') {
                        console.log("MultiPoint")
                    } else {
                        console.log(featureType)
                    }
                    v.geometry.coordinates = newArr
                } else if (v?.properties?.Geometry?.MultiGeometry?.geometryMember) { //MultiGeometry                    
                    let _multigeometryType = v.properties.Geometry.MultiGeometry.geometryMember
                    const _properties = { ...v.properties }
                    delete _properties.Geometry
                    _multigeometryType.forEach(element => {
                        if (element.Polygon && element.Polygon._content_) {
                            let object = {
                                type: "Feature",
                                properties: _properties,
                                geometry: {
                                    type: "Polygon",
                                    coordinates: []
                                }
                            }
                            if (element.Polygon._content_.outerBoundaryIs) {
                                let _polygon = toArray(element.Polygon._content_.outerBoundaryIs.LinearRing.coordinates)
                                object.geometry.coordinates.push(_polygon)
                            }

                            if (element.Polygon._content_.innerBoundaryIs && element.Polygon._content_.innerBoundaryIs.length > 0) {
                                element.Polygon._content_.innerBoundaryIs.forEach(item => {
                                    let _polygon = toArray(item.LinearRing.coordinates)
                                    object.geometry.coordinates.push(_polygon)
                                });
                            }
                            _multiGeometry.push(object)
                        } else if (element.Point && element.Point._content_) {
                            let object = {
                                type: "Feature",
                                properties: _properties,
                                geometry: {
                                    type: "Point",
                                    coordinates: []
                                }
                            }
                            let _line = element.Point._content_.coordinates.split(" ").flatMap(str => (str.split(",").map(str => +str)))
                            object.geometry.coordinates = _line
                            _multiGeometry.push(object)
                        }
                    });
                }
            }

            if (_multiGeometry.length > 0) {
                shape.features = _multiGeometry
            }

        }

        return shape
    }

    function toArray(polygon) {
        const final = polygon.split(" ");
        return final.flatMap(str => ([str.split(",").map(str => +str)]));
    }

    useEffect(() => {
        const loadGeoJsonViews = async () => {
            if (viewer && viewer.current && viewer.current.cesiumElement && modelListWFS && modelListWFS.length > 0) {
                for (const model of modelListWFS) {
                    if (model.data.bbox) {
                        const param = {
                            service: 'WFS',
                            request: 'GetFeature',
                            typeName: model.data.layers,
                            outputFormat: model.data.outputFormat ? model.data.outputFormat : 'application/json',
                            bbox: model.data.bbox.join(',')
                        };
                        if (model.data.version) {
                            param.version = model.data.version
                        }
                        if (model.data.projectPlaneToWGS84) {
                            param.srsName = 'EPSG:4326'
                            param.bbox = model.data.projectPlaneToWGS84.bbox.join(',')
                        }

                        let wfs_url
                        let config = { params: param }
                        if (model.data.username && model.data.password && model.data.isUseCredential) {
                            const Authorization = btoa(`${model.data.username.trim()}:${model.data.password}`)
                            config.headers = {
                                'Authorization': `Basic ${Authorization}`,
                                'Access-Control-Allow-Origin': '*',
                            }
                        }

                        if (model.data.version === '2.0.0') {
                            const epsgCode = model.data.epsg
                            if (epsgCode !== 4326) {
                                let getCodeUrl = 'https://epsg.io/' + epsgCode + '.proj4'
                                var x = await axios.get(getCodeUrl)
                                proj4.defs('EPSG:' + epsgCode, x.data)
                                register(proj4)
                            }
                            config.params.bbox = config.params.bbox + `,EPSG:${epsgCode}`
                            config.params.version = '1.1.0'
                            config.params.srsName = `EPSG:${epsgCode}`
                            // config.params.maxFeatures = 1000
                        }

                        if (['GML2', 'GML3', 'text/xml; subtype=gml/3.2.1'].includes(model.data.outputFormat)) {
                            let requestfeature = await axios.get(model.src, config).catch(error => console.log(error))
                            wfs_url = requestfeature && requestfeature.data ? gml2geojson(requestfeature.data, model.data.version) : ''
                            // console.log(JSON.stringify(wfs_url))
                        } else {
                            param.srsName = 'EPSG:4326'
                            param.bbox = model.data.bbox.join(',') + ',EPSG:4326'
                            config.params = param
                            let requestfeature = await axios.get(model.src, config).catch(error => console.log(error))
                            wfs_url = requestfeature && requestfeature.data ? requestfeature.data : ''
                        }

                        const ref = React.createRef()
                        let isShow = true
                        if (geoJsonViewLink) {
                            let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds();
                            if (!visibleTileViewIds.includes(model.id)) {
                                isShow = false
                            }
                            if (projectStore.hiddenModelLinkIDs.includes(model.id)) {
                                isShow = false
                            }
                        } else {
                            const isIdInList = (id, list) => {
                                return list.some(obj => obj.modelId === id);
                            }
                            if (isIdInList(model.id, userGroupStore.nodeNoAccessRights)) {
                                isShow = false;
                            } else {
                                let isExist = projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0 ? projectStore.visibleTilesets.find(c => c.modelId === model.id) : false
                                if (isExist) {
                                    isShow = isExist.isVisible
                                }
                            }
                        }

                        setGeoJsonViews(geoJsonViews => [
                            ...geoJsonViews,
                            <GeoJsonDataSource
                                key={geoJsonViewLink ? `${model.id}-tile-link` : `${model.id}-tile`}
                                show={isShow}
                                type={'geojson'}
                                ref={ref}
                                data={wfs_url}
                                onClick={(e) => {
                                    if (canPicking()) {
                                        var pickedObject = viewer.current.cesiumElement.scene.pick(e.position)
                                        onClickFeature(pickedObject, model)
                                    }
                                }}
                                onLoad={g => {
                                    for (var i = 0; i < g.entities.values.length; i++) {
                                        var entity = g.entities.values[i];
                                        entity.modelId = model._id
                                        entity.modelType = 'geojson'
                                        if ((entity.point || entity.billboard) && model.data.style && model.data.style.point) {
                                            if (model.data.style.point?.isShowPointAs === 'Billboard') {
                                                if (entity.billboard.image._value.tagName === 'CANVAS') {
                                                    entity.billboard.image = new PinBuilder().fromColor(new Color.fromCssColorString(model.data.style.point.color || 'white').withAlpha(Utils.flipInt(model.data.style.point.alpha)), (model.data.style.point.pixelSize * 3) || entity.billboard.image._value.width)
                                                } else {
                                                    entity.billboard.color = new Color.fromCssColorString(model.data.style.point.color || 'white').withAlpha(Utils.flipInt(model.data.style.point.alpha))
                                                    if (model.data.style.point.pixelSize) {
                                                        entity.billboard.width = model.data.style.point.pixelSize
                                                        entity.billboard.height = model.data.style.point.pixelSize
                                                    }
                                                }
                                                if(model.data.clampToGround){
                                                    entity.billboard.heightReference = HeightReference.CLAMP_TO_GROUND
                                                }
                                            } else {
                                                entity.billboard = undefined;
                                                entity.point = new PointGraphics({
                                                    color: new Color.fromCssColorString(model.data.style.point.color || 'white').withAlpha(Utils.flipInt(model.data.style.point.alpha)),
                                                    pixelSize: model.data.style.point.pixelSize || 10,
                                                    outlineColor: model.data.style.point.outlineColor ? new Color.fromCssColorString(model.data.style.point.outlineColor) : Color.BLACK,
                                                    outlineWidth: model.data.style.point.outlineWidth ? model.data.style.point.outlineWidth : 0
                                                });
                                                if(model.data.clampToGround){
                                                    entity.point.heightReference = HeightReference.CLAMP_TO_GROUND
                                                }
                                            }
                                        } else if (entity.polyline && model.data.style && model.data.style.line) {
                                            if (model.data.style.line.width) entity.polyline.width = model.data.style.line.width
                                            entity.polyline.material = model.data.style.line.color ? new Color.fromCssColorString(model.data.style.line.color).withAlpha(Utils.flipInt(model.data.style.line.alpha)) : Color.WHITE
                                        } else if (entity.polygon && model.data.style && model.data.style.polygon) {
                                            entity.polygon.material = model.data.style.polygon.color ? new Color.fromCssColorString(model.data.style.polygon.color).withAlpha(Utils.flipInt(model.data.style.polygon.alpha)) : Color.WHITE
                                            entity.polygon.outlineColor = model.data.style.polygon.outlineColor ? new Color.fromCssColorString(model.data.style.polygon.outlineColor) : Color.BLACK
                                            if(model.data.clampToGround){
                                            entity.polygon.perPositionHeight = false;
                                            entity.polygon.height = undefined;
                                            entity.polygon.extrudedHeight = undefined;
                                            }else{
                                                if (model.data.style.polygon.height) entity.polygon.height = Number(model.data.style.polygon.height);
                                                if (model.data.style.polygon.extrudedHeight) entity.polygon.extrudedHeight = Number(model.data.style.polygon.extrudedHeight) + Number(model.data.style.polygon.height || 0);
                                                if (model.data.style.polygon.height || model.data.style.polygon.extrudedHeight) entity.polygon.perPositionHeight = false;
                                                var clampToGround = true;
                                                var positions = entity.polygon.hierarchy.getValue().positions;
                                                for (var n = 0; n < positions.length; n++) {
                                                    var cartographicPosition = viewer.current.cesiumElement.scene.globe.ellipsoid.cartesianToCartographic(positions[n]);
                                                    if (cartographicPosition.height !== 0) {
                                                        clampToGround = false;
                                                        break;
                                                    }
                                                }
                                                if (clampToGround) {
                                                    entity.polygon.height = undefined;
                                                }
                                            }
                                        }
                                    }
                                    if (viewer?.current?.cesiumElement?._cesiumWidget) {
                                        if (viewer.current.cesiumElement?.scene?.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
                                    }
                                }}
                                clampToGround={model.data.clampToGround === true}
                                onError={(err) => {
                                    console.log("Error load GeoJsonDataSource: " + model.name)
                                }}
                            />
                        ])
                    }
                }
            }
        }
        loadGeoJsonViews();

        return () => {
            setGeoJsonViews([]);
        }
    }, [modelListWFS])

    return <>{geoJsonViews.map(geoJsonView => geoJsonView)}</>
}

export default inject('projectStore', 'uiStore', 'modelStore', 'userGroupStore')(observer(GeoJsonView))
