import { observable, action, decorate, toJS } from 'mobx'
import { ProjectRequest, FileRequest } from '../requests'
import { message } from 'antd'
import i18n from 'i18next';
import commonStore from './commonStore';

class CapturesStore {

  isLoading = false
  captureList = []
  isDrawerCaptureVisible = false
  cameraData = false
  clickAdd = false
  updateDataFromSocket = false

  setUpdateDataFromSocket = data => {
    this.updateDataFromSocket = data
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }

  setCameraData = camData => {
    this.cameraData = camData
  }

  setClickAdd = status => {
    this.clickAdd = status
  }

  addCaptureViews = async (data, isUpdateThumnail,logInfo, callback) => {
    this.setLoadingProgress(true)
    return (
      ProjectRequest.createViewPoint(data)
        .then(async info => {
          if (isUpdateThumnail) {
            await this.updateProjectThumnail(isUpdateThumnail).then(res => {
              callback(res)
              this.captureList.push(info.data)
              this.setLoadingProgress(false)
              message.success(i18n.t('view-added'), 5)
              commonStore.loggingFunction('Add viewpoint','success',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
            })
              .catch(error => {
                this.setLoadingProgress(false)
              })
          } else {
            this.captureList.push(info.data)
            message.success(i18n.t('view-added'), 5)
            commonStore.loggingFunction('Add viewpoint','success',logInfo.startTime,logInfo.user,logInfo.project )
          }
        })
        .catch(error => {
          console.log(error)
          message.error(i18n.t('an-error-occurred'))
          commonStore.loggingFunction('Add viewpoint','failed',logInfo.startTime,logInfo.user,logInfo.project )
        })
        .finally(() => {
          this.setLoadingProgress(false)
        })
    )
  }

  setCaptureList = captures => {
    this.captureList = captures
  }

  getCaptureList = async (projectId) => {
    this.setLoadingProgress(true)
    await ProjectRequest.getProjectViewPoints(projectId).then((res) => {
      this.captureList = res.data
      this.setLoadingProgress(false)
    }).catch(err => this.setLoadingProgress(false))
  }

  deleteCaptureViews = async (id, isUpdateThumnail,logInfo, callback) => {
    this.setLoadingProgress(true)
    ProjectRequest.deleteViewPoint(id)
      .then((res) => {
       if(res?.data?.status ==='success'){
        const index = this.captureList.findIndex(x => x.id === id)
        this.captureList.splice(index, 1)
        const viewPoint = res.data
        const viewPointImage = viewPoint.image
        if(viewPointImage?.id){
          return FileRequest.delete(viewPointImage.id)
        }
       }
      })
      .then(async () => {
        if (isUpdateThumnail && this.captureList && this.captureList.length > 0) {
          await this.updateProjectThumnail(isUpdateThumnail).then(res => {
            callback(res)
          })
            .catch(error => {
              this.setLoadingProgress(false)
            })
        }
        message.success(i18n.t('view-deleted'))
        this.setLoadingProgress(false)
        commonStore.loggingFunction('Delete viewpoint','success',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
      })
      .catch(error => {
        console.log(error)
        message.error(i18n.t('an-error-occurred'))
        this.setLoadingProgress(false)
        commonStore.loggingFunction('Delete viewpoint','failed',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
      })
  }

  updateCaptureView = async (captureId, captureData, reName, isUpdateThumnail,logInfo, callback) => {
    let text = reName ? i18n.t('rename') : i18n.t('updated')
    this.setLoadingProgress(true)
    return ProjectRequest.updateCaptureName(captureId, captureData)
      .then(async (response) => {
        let index
        for (let i = 0; i < this.captureList.length; i++) {
          if (this.captureList[i].id === captureId) {
            index = i
          }
        }

        if(response.data?.status ==='success'){
          const currentDate = new Date().toISOString();
          this.captureList[index] = {
            ...this.captureList[index],
            ...captureData,
            updatedAt : currentDate
          }
          if (isUpdateThumnail) {
            await this.updateProjectThumnail(isUpdateThumnail).then(res => {
              callback(res)
            })
              .catch(error => {
                this.setLoadingProgress(false)
              })
          }
          this.setLoadingProgress(false)
          message.success(i18n.t('capture-successfully', { text: text }))
          commonStore.loggingFunction(`${text} viewpoint`,'success',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
        }else{
          message.error(i18n.t('capture-failed', { text: text }))
          this.setLoadingProgress(false)
          commonStore.loggingFunction(`${text} viewpoint`,'failed',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
        }
      })
      .catch(error => {
        console.log(error)
        message.error(i18n.t('capture-failed', { text: text }))
        this.setLoadingProgress(false)
        commonStore.loggingFunction(`${text} viewpoint`,'failed',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
      })
  }

  toggleDrawerCaptureVisible(state) {
    this.isDrawerCaptureVisible = state
  }

  async updateProjectThumnail(projectId, body) {
    return new Promise((resolve, reject) => {
      ProjectRequest.updateProjectThumnail(projectId, body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        reject(err)
      })
    })
  }
}

decorate(CapturesStore, {
  isLoading: observable,
  captureList: observable,
  isDrawerCaptureVisible: observable,
  setLoadingProgress: action,
  addCaptureViews: action,
  deleteCaptureViews: action,
  toggleDrawerCaptureVisible: action,
  cameraData: observable,
  setCameraData: action,
  setClickAdd: action,
  clickAdd: observable,
  setCaptureList: action,
  updateCaptureView: action,
  updateProjectThumnail: action,
  getCaptureList: action,
  updateDataFromSocket: observable,
  setUpdateDataFromSocket: action

  // getCurrentCamera:action
})

export default new CapturesStore()
