import { observable, action, decorate } from 'mobx'
import { ObjectsQueriesRequest, ObjectInforRequest } from '../requests';

class ObjectQueryStore {
  isLoading = false
  objectsQueriesDetail = {}
  listObjectsQuery = []
  isShowListQuery = false
  styleQuery = {
    data: {
      result: [
        {
          id: 'color',
          value: '#FF0000',
          checked: true,
        },
        {
          id: 'alpha',
          value: 0,
          checked: false,
        },
        {
          id: 'show',
          value: true,
          checked: false
        }
      ],
      scene: [
        {
          id: 'color',
          value: '#808080',
          checked: true,
        },
        {
          id: 'alpha',
          value: 0,
          checked: false,
        },
        {
          id: 'show',
          value: true,
          checked: false
        }
      ],
    },
    status: false
  }
  setStyleQuery = (data) => {
    this.styleQuery = data
  }

  setIsShowListQuery = (data) => {
    this.isShowListQuery = data
  }
  getTotalObjectModelForProject = (projectID) => {
    return new Promise((resolve, reject) => {
      ObjectInforRequest.getTotalObjectModelForProject(projectID)
        .then(response => {
          resolve(response?.data?.total || 0)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  getListGuidHighlight = (projectID, data) => {
    return new Promise((resolve, reject) => {
      ObjectInforRequest.getListGuidHighlight(projectID, data)
        .then(response => {
          resolve(response?.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  setDetail = data => {
    this.objectsQueriesDetail = data
  }
  setIsLoading = data => {
    this.isLoading = data
  }

  setListObjectsQuery = data => {
    this.listObjectsQuery = data
  }


  getDetail = async (id) => {
    return new Promise((resolve, reject) => {
      ObjectsQueriesRequest.getById(id)
        .then(response => {
          this.setDetail(response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  getByProjectId = async (projectId) => {
    return new Promise((resolve, reject) => {
      ObjectsQueriesRequest.getByProjectId(projectId)
        .then(response => {
          this.listObjectsQuery = response.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  create = async (body) => {
    return new Promise((resolve, reject) => {
      ObjectsQueriesRequest.create(body)
        .then(response => {
          this.setListObjectsQuery([...this.listObjectsQuery, response.data])
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async update(id, data) {
    return new Promise((resolve, reject) => {
      if (id) {
        ObjectsQueriesRequest.update(id, data)
          .then((response) => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      }
      else reject()
    })
  }

  delete = async (id) => {
    return new Promise((resolve, reject) => {
      ObjectsQueriesRequest.delete(id)
        .then(response => {
          this.listObjectsQuery = this.listObjectsQuery.filter(c => c.id !== id)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  checkObjectInforsReady = async (id, modelList) => {
    return new Promise((resolve, reject) => {
      ObjectInforRequest.checkObjectInforsReady(id, modelList)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}

decorate(ObjectQueryStore, {
  isLoading: observable,
  objectsQueriesDetail: observable,
  listObjectsQuery: observable,
  setDetail: action,
  getDetail: action,
  update: action,
  delete: action,
  create: action,
  setListObjectsQuery: action,
  getByProjectId: action,
  getListGuidHighlight: action,
  isShowListQuery: observable,
  setIsShowListQuery: action,
  setIsLoading: action,
  getTotalObjectModelForProject: action,
  setStyleQuery: action,
  styleQuery: observable,
})
/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new ObjectQueryStore()